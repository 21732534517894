<!-- 病患详情 -->
<template>
	<div>
		<el-form ref="form" :model="form" :rules="rules">
			<el-row type="flex" justify="space-around">
				<el-col :span="6">
					<el-form-item label="医院" prop="serialNumber">
						<el-input v-model="form.serialNumber"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="病历号" prop="orderDate">
						<el-date-picker value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="发起时间" v-model="form.orderDate"
						 style="width: 100%;"></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="医生" prop="orderId">
						<el-input clearable v-model="form.orderId"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-around">
				<el-col :span="6">
					<el-form-item label="姓名" prop="orderId">
						<el-input clearable v-model="form.orderId"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="联系方式" prop="orderId">
						<el-input clearable v-model="form.orderId"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="性别" prop="productPackageId">
						<el-select filterable  clearable v-model="form.productPackageId" placeholder="请选择" class="select-sty">
							<el-option v-for="item in ProductPackageData" :key="item.productPackageId" :label="item.packageName" :value="item.productPackageId">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-around">
				<el-col :span="6">
					<el-form-item label="年龄" prop="batchNumber">
						<el-input v-model="form.batchNumber"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="住址" prop="orderStatus">
						<el-select filterable  clearable v-model="form.orderStatus" placeholder="请选择" class="select-sty">
							<el-option v-for="item in InventoryStatusData" :key="item.no" :label="item.label" :value="item.no">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="详细住址" prop="remark">
						<el-input clearable v-model="form.remark"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-around">
				<el-col :span="22">
					<el-form-item label="术前诊断" prop="types">
						<el-checkbox-group v-model="form.types" class="select-sty">
							<el-checkbox label="病窦" name="type"></el-checkbox>
							<el-checkbox label="房颤伴长RR间期" name="type"></el-checkbox>
							<el-checkbox label="神经介导性晕厥" name="type"></el-checkbox>
							<el-checkbox label="扩张型心肌病" name="type"></el-checkbox>
							<el-checkbox label="肥厚型心肌病" name="type"></el-checkbox>
							<el-checkbox label="先天性长QT综合征" name="type"></el-checkbox>
							<el-checkbox label="其他" name="type"></el-checkbox>
						</el-checkbox-group>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-around">
				<el-col :span="6">
					<el-form-item label="植入时间" prop="outboundDate">
						<el-date-picker value-format="yyyy-MM-dd HH:mm:ss" clearable type="datetime" placeholder="出库时间" v-model="form.outboundDate"
						 style="width: 100%;"></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="手术耗时" prop="outboundDate">
						<el-date-picker value-format="yyyy-MM-dd HH:mm:ss" clearable type="datetime" placeholder="出库时间" v-model="form.outboundDate"
						 style="width: 100%;"></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="手术访问日期" prop="outboundDate">
						<el-date-picker value-format="yyyy-MM-dd HH:mm:ss" clearable type="datetime" placeholder="出库时间" v-model="form.outboundDate"
						 style="width: 100%;"></el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-around">
				<el-col :span="6">
					<el-form-item label="手术类型" prop="remark">
						<el-input clearable v-model="form.remark"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="是否更换">
						<el-radio-group v-model="form.resource">
							<el-radio label="是"></el-radio>
							<el-radio label="否"></el-radio>
						</el-radio-group>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="术式" prop="remark">
						<el-input clearable v-model="form.remark"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-around">
				<el-col :span="6">
					<el-form-item label="A组" prop="remark">
						<el-input clearable v-model="form.remark"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="导管室" prop="remark">
						<el-input clearable v-model="form.remark"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="首次访问时间" prop="outboundDate">
						<el-date-picker value-format="yyyy-MM-dd HH:mm:ss" clearable type="datetime" placeholder="出库时间" v-model="form.outboundDate"
						 style="width: 100%;"></el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-around">
				<el-col :span="22">
					<el-form-item label="术者1" prop="remark">
						<el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">
							{{tag}}
						</el-tag>
						<el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
						 @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
						</el-input>
						<el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-around">
				<el-col :span="6">
					<el-form-item label="并发症" prop="remark">
						<el-input clearable v-model="form.remark"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="术中有无发现其他植入物">
						<el-radio-group v-model="form.resource">
							<el-radio label="是"></el-radio>
							<el-radio label="否"></el-radio>
						</el-radio-group>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="特殊情况备注" prop="remark">
						<el-input clearable v-model="form.remark"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-around">
				<el-col :span="22">
					<el-button type="primary">确定</el-button>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>
	export default {
		components: {},
		methods: {
			//分页组件
			pages(current, size) {

			},
			//查看该医生的都患者
			CheckPatient() {
				this.$router.push({
					path: '/DoctorsPatient'
				})
			},
			// 搜索
			querys() {

			},
			//重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 选中事件
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			handleClose(tag) {
				this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
			},

			showInput() {
				this.inputVisible = true;
				this.$nextTick(_ => {
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},

			handleInputConfirm() {
				let inputValue = this.inputValue;
				if (inputValue) {
					this.dynamicTags.push(inputValue);
				}
				this.inputVisible = false;
				this.inputValue = '';
			}
		},
		data() {
			return {
				//form表单查询
				form: {
					types:[]
				},
				//form表单验证规则
				rules: {},
				//性别数据
				ProductPackageData: [],
				dynamicTags: ['标签一', '标签二', '标签三'],
				inputVisible: false,
				inputValue: '',
				InventoryStatusData:[]
			}
		},
		created() {},
		mounted() {}
	}
</script>

<style scoped>
	.el-tag+.el-tag {
		margin-left: 10px;
	}

	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
	}
</style>
